import useApiMutationWithAuth, {
  IUseApiMutationResponse,
} from "src/api/hooks/useApiMutationWithAuth.hook";

const apiPath = "/auth/logout";

export interface ILogoutResponseDTO {}

export interface IUseLogout
  extends IUseApiMutationResponse<void, ILogoutResponseDTO> {}

function useLogout(): IUseLogout {
  const useMutationResponse = useApiMutationWithAuth<void, ILogoutResponseDTO>({
    method: "POST",
    apiPath,
    includeMagicDidToken: true,
  });

  return { ...useMutationResponse };
}
export default useLogout;
