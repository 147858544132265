import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import MuiTooltip from "@mui/material/Tooltip";

interface Props {
  message: React.ReactNode | string;
}
const Tooltip: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  children,
}) => {
  return (
    <Box display="flex" alignItems={"center"}>
      <Box mr={1}>{children}</Box>
      <MuiTooltip title={message}>
        <InfoIcon sx={{ fontSize: 20 }} />
      </MuiTooltip>
    </Box>
  );
};

export default Tooltip;
