/**
 * Should properly document this, but:
 *
 *  - naming either indicates the stirng (if it's short) or the location of the string
 *  - strings need to be static
 */

const strings = {
  account_explanation: `Some explanation of what your "account" is or what you're seeing on his page.`,
  free_cash_explanation: `Some explanation of what your "free cash" is or what you're seeing on his page.`,
  your_account: `Your account`,
};

export default strings;
