import React from "react";
import ReactDOM from "react-dom/client";
import { WagmiConfig } from "wagmi";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./context/auth.context";
import { ThemeProvider } from "./context/muiTheme.context";
import { ReactQueryProvider } from "./context/reactQuery.context";
import { client } from "./config/web3";

window.Buffer = window.Buffer || require("buffer").Buffer;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ReactQueryProvider>
      <ThemeProvider>
        <WagmiConfig client={client}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </WagmiConfig>
      </ThemeProvider>
    </ReactQueryProvider>
  </React.StrictMode>
);
