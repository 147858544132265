import { useContext } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthContext } from "src/context/auth.context";
import LandingPage from "./pages/landing/Landing.page";
import AccountSetUpPage from "./pages/onboarding/AccountSetUp.page";
import { SignUpStatus } from "./api/hooks/auth/useCurrentUser";
import AuthenticatedApp from "./AuthenticatedApp";

function App() {
  const authContext = useContext(AuthContext);

  if (authContext.isCheckingAuthStatus) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      {!authContext.currentUser ? (
        <LandingPage />
      ) : authContext.currentUser?.signUpStatus ===
        SignUpStatus.SIGN_UP_SUCCESSFUL ? (
        <AuthenticatedApp />
      ) : (
        <AccountSetUpPage />
      )}
    </Box>
  );
}

export default App;
