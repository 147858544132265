import useApiWithAuth, {
  IUseApiResponse,
} from "src/api/hooks/useApiWithAuth.hook";

export const apiPath = "/auth/me";

export enum SignUpStatus {
  SIGNED_UP = "SIGNED_UP",
  PROCESSING_SIGN_UP = "PROCESSING_SIGN_UP",
  SIGN_UP_SUCCESSFUL = "SIGN_UP_SUCCESSFUL",
  SIGN_UP_FAILED = "SIGN_UP_FAILED",
}

export interface CurrentUser {
  signUpStatus: SignUpStatus;
}

export interface CurrentUserDTO {
  user: CurrentUser;
}

interface IUseCurrentUser extends IUseApiResponse<CurrentUserDTO> {
  currentUser?: CurrentUser;
}

function useCurrentUser(refetchInterval?: number): IUseCurrentUser {
  const useFetchResponse = useApiWithAuth<CurrentUserDTO>({
    method: "GET",
    apiPath,
    refetchInterval,
  });

  console.log("useFetchResponse", useFetchResponse);

  return {
    ...useFetchResponse,
    currentUser: useFetchResponse?.data?.user,
  };
}
export default useCurrentUser;
