import useApiMutationWithAuth, {
  IUseApiMutationResponse,
} from "src/api/hooks/useApiMutationWithAuth.hook";

const apiPath = "/auth/authenticate";

export interface ILoginResponseDTO {}

export interface IUseLogin
  extends IUseApiMutationResponse<void, ILoginResponseDTO> {}

function useLogin(): IUseLogin {
  const useMutationResponse = useApiMutationWithAuth<void, ILoginResponseDTO>({
    method: "POST",
    apiPath,
    includeMagicDidToken: true,
  });

  return { ...useMutationResponse };
}
export default useLogin;
