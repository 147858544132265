import { useQuery } from "@tanstack/react-query";
import { constructApiUrl } from "src/api/utils.api";
import magic from "src/utils/magic";

// Additional options that can be sent to the api
export interface IApiOptions {
  body?: any;
}

export interface IUseApiResponse<T> {
  data: T | undefined;
  error: any | null;
  isDone: boolean;
  isLoading: boolean;
  refetch: (body?: any) => void;
}

export interface IUseApiWithAuthInputs {
  method: string;
  apiPath: string;
  runOnInit?: boolean;
  includeMagicDidToken?: boolean;
  options?: IApiOptions;
  refetchInterval?: number;
}

// Hook to handle sending requests to the api
function useApiWithAuth<T>({
  method,
  apiPath,
  options,
  runOnInit = true,
  includeMagicDidToken,
  refetchInterval,
}: IUseApiWithAuthInputs): IUseApiResponse<T> {
  const {
    data,
    error,
    isLoading,
    isSuccess: isDone,
    refetch,
  } = useQuery({
    enabled: runOnInit,
    refetchOnWindowFocus: false,
    retry: false,
    queryKey: [apiPath],
    refetchInterval,
    queryFn: async (): Promise<T> => {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
      };

      if (includeMagicDidToken) {
        const didToken = await magic.user.getIdToken();
        headers.Authorization = `Bearer ${didToken}`;
      }

      const response = await fetch(constructApiUrl(apiPath), {
        method,
        body: options?.body,
        headers,
        credentials: "include",
      });
      const responseData = await response.json();

      if ("error" in responseData) {
        throw responseData.error;
      }
      return responseData;
    },
  });

  return {
    data,
    error,
    isDone,
    isLoading,
    refetch,
  };
}

export default useApiWithAuth;
