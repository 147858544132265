import React, { useContext } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import strings from "src/strings";
import { AuthContext } from "src/context/auth.context";
import Page from "src/components/page/Page";
import Tooltip from "src/components/tooltip/Tooltip";

import Greeting from "./components/Greeting";
import FinancialProducts from "./components/FinancialProducts";

const HomePage: React.FC<{}> = () => {
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    return null;
  }

  return (
    <Page>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Greeting />
        </Grid>

        <Grid item xs={12} mb={4}>
          <Tooltip message={strings.account_explanation}>
            <Typography variant="h4">{strings.your_account}</Typography>
          </Tooltip>
        </Grid>

        <Grid item xs={12} mb={8}>
          <Box display="flex" mb={4}>
            <Typography variant="h5" mr={2}>
              Free cash:
            </Typography>
            <Tooltip message={strings.free_cash_explanation}>
              <Typography
                variant="h5"
                sx={{
                  textDecoration: "underline dotted",
                }}
              >
                $0
              </Typography>
            </Tooltip>
          </Box>
          <Button variant="contained">Add Funds</Button>
        </Grid>

        <Grid item xs={12} mb={8}>
          <Typography variant="h5" mb={4}>
            Holdings
          </Typography>
          <Typography variant="body1" mb={4}>
            None
          </Typography>
        </Grid>

        <Grid item xs={12} mb={8}>
          <Typography variant="h5" mb={4}>
            DeFi Protocols
          </Typography>
          <FinancialProducts />
        </Grid>
      </Grid>
    </Page>
  );
};

export default HomePage;
