import { useMutation, useQueryClient } from "@tanstack/react-query";
import { constructApiUrl } from "src/api/utils.api";
import magic from "src/utils/magic";

export interface IUseApiMutationResponse<TMutation, TResponse> {
  data: TResponse | null;
  error: any | null;
  isDone: boolean;
  isLoading: boolean;
  mutate: (t: TMutation) => void;
  mutateAsync: (t: TMutation) => Promise<TResponse>;
}

export interface IUseApiMutationWithAuthInputs {
  method: string;
  apiPath: string;
  includeMagicDidToken?: boolean;
}

// Hook to handle sending requests to the api
function useApiMutationWithAuth<TMutation, TResponse>({
  method,
  apiPath,
  includeMagicDidToken,
}: IUseApiMutationWithAuthInputs): IUseApiMutationResponse<
  TMutation,
  TResponse
> {
  const queryClient = useQueryClient();

  const {
    error,
    data,
    isLoading,
    isSuccess: isDone,
    mutate,
    mutateAsync,
  } = useMutation({
    mutationFn: async (body: TMutation) => {
      const headers: HeadersInit = {
        "Content-Type": "application/json",
      };

      if (includeMagicDidToken) {
        const didToken = await magic.user.getIdToken();
        headers.Authorization = `Bearer ${didToken}`;
      }

      const response = await fetch(constructApiUrl(apiPath), {
        method,
        body: JSON.stringify(body),
        credentials: "include",
        headers,
      });
      const responseData = await response.json();

      if ("error" in responseData) {
        throw responseData.error;
      }
      return responseData;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [apiPath] });
    },
  });

  return {
    data,
    error,
    isDone,
    mutate,
    mutateAsync,
    isLoading: isLoading,
  };
}

export default useApiMutationWithAuth;
