import React, { useEffect } from "react";
import useCurrentUser from "src/api/hooks/auth/useCurrentUser";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Page from "src/components/page/Page";
import { SignUpStatus } from "src/api/hooks/auth/useCurrentUser";

const AccountSetUpPage: React.FC<{}> = () => {
  const { currentUser } = useCurrentUser(1000);

  useEffect(() => {
    if (currentUser?.signUpStatus === SignUpStatus.SIGN_UP_FAILED) {
      window.alert("Something went wrong with set up. The team is on it.");
    }
  }, [currentUser]);

  return (
    <Page>
      <Box mt={6} mb={12}>
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            fontWeight: 600,
          }}
          mb={4}
        >
          Give us a second as we set up your account
        </Typography>
        <Box mt={4} textAlign={"center"}>
          <CircularProgress />
        </Box>
      </Box>
    </Page>
  );
};

export default AccountSetUpPage;
