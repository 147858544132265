import { createContext, useState, useEffect, PropsWithChildren } from "react";
import { ethers } from "ethers";
import magic from "src/utils/magic";
import useCurrentUser from "src/api/hooks/auth/useCurrentUser";
import useSignMessage, {
  ISignedMessageResponseDTO,
} from "src/api/hooks/web3/useSignMessage";

//@ts-ignore
const provider = new ethers.providers.Web3Provider(magic.rpcProvider);

interface Web3ContextData {
  address?: string;
  signMessage: (message: string) => Promise<ISignedMessageResponseDTO>;
}

const Web3Context = createContext<Web3ContextData>({} as Web3ContextData);

const Web3Provider: React.FC<PropsWithChildren> = ({ children }) => {
  const { currentUser } = useCurrentUser();
  const [address, setAddress] = useState<string>();
  const { signMessage: signMessageApi } = useSignMessage();

  const signMessage = async (rawMessage: string) => {
    if (!address) {
      throw new Error("User address not configured.");
    }
    const signer = provider.getSigner();
    const signedMessage = await signer.signMessage(rawMessage);
    const fullyExpandedSig = ethers.utils.splitSignature(signedMessage);

    return await signMessageApi({
      signingAddress: address,
      unsignedMessage: rawMessage,
      signedMessage,
      fullyExpandedSig,
    });
  };

  /**
   * For now, we're just storing the magic-link generated address.
   * We will update this when we support adding other signers.
   */
  useEffect(() => {
    const getAddress = async () => {
      try {
        const userMetadata = await magic.user.getMetadata();
        if (!userMetadata.publicAddress) {
          throw new Error("No address associated to the user.");
        }
        setAddress(userMetadata.publicAddress);
      } catch (e) {
        window.alert("Failed to fetch your public address. The team is on it.");
      }
    };

    if (currentUser && !address) {
      getAddress();
    }
  }, [currentUser, address]);

  return (
    <Web3Context.Provider value={{ address, signMessage }}>
      {children}
    </Web3Context.Provider>
  );
};

export { Web3Context, Web3Provider };
