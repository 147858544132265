const config = {
  api: {
    baseUrl: process.env.REACT_APP_API_URL || "http://localhost:3032",
    version: process.env.REACT_APP_API_VERSION || "0",
  },
  magic: {
    publishableApiKey:
      process.env.REACT_APP_MAGIC_PUBLISHABLEAPI_KEY ||
      "pk_live_27FADCFA7DC85971",
    testMode: false,
  },
};

export default config;
