import { Magic } from "magic-sdk";
import config from "src/config";

const magic = new Magic(config.magic.publishableApiKey, {
  testMode: config.magic.testMode,
});

magic.preload();

export default magic;
