import React, { useContext, useState, FormEvent } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

import { AuthContext } from "src/context/auth.context";
import Page from "src/components/page/Page";

const validateEmail = (email: string) => {
  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

const LandingPage: React.FC<{}> = () => {
  const authContext = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<string>();

  const handleAuth = async (e: FormEvent) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError("Please enter an email");
    } else {
      setEmailError(undefined);
      try {
        await authContext.authenticate(email);
      } catch (e: any) {
        window.alert(e?.message || e);
      }
    }
  };

  return (
    <Page>
      <Box mt={6} mb={12}>
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: 600,
          }}
          mb={4}
        >
          Sugarcane
        </Typography>
        <form onSubmit={handleAuth}>
          <Box mt={6} mb={4} textAlign="center">
            <TextField
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              defaultValue={email}
              error={!!emailError}
              helperText={emailError}
              disabled={authContext.isAuthenticating}
              type="email"
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              onClick={handleAuth}
              size="large"
              disabled={authContext.isAuthenticating}
            >
              Sign Up / Log In
            </Button>
          </Box>
        </form>
        {authContext.isAuthenticating ? (
          <Box mt={4} textAlign={"center"}>
            <CircularProgress />
          </Box>
        ) : null}
      </Box>
    </Page>
  );
};

export default LandingPage;
