import { createContext, useState, useEffect, PropsWithChildren } from "react";
import magic from "src/utils/magic";
import useCurrentUser, { CurrentUser } from "src/api/hooks/auth/useCurrentUser";
import useLogin from "src/api/hooks/auth/useLogin";
import useLogout from "src/api/hooks/auth/useLogout";

interface AuthContextData {
  isCheckingAuthStatus: boolean;
  currentUser?: CurrentUser;
  refreshUser: () => void;
  authenticate: (email: string) => Promise<void>;
  isAuthenticating: boolean;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    currentUser,
    error: errorLoadingUser, // TODO handle
    isLoading: isCheckingAuthStatus,
    refetch: refreshUser,
  } = useCurrentUser();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const { mutateAsync: login } = useLogin();
  const { mutateAsync: apiLogout } = useLogout();

  useEffect(() => {
    if (errorLoadingUser) {
      window.alert(errorLoadingUser?.message || errorLoadingUser);
    }
  }, [errorLoadingUser]);

  const authenticate = async (email: string) => {
    if (isAuthenticating) {
      return;
    }
    setIsAuthenticating(true);
    try {
      await magic.auth.loginWithMagicLink({
        email,
        showUI: true,
      });
      await login();
      await refreshUser();
      setIsAuthenticating(false);
    } catch (e) {
      console.error(e);
      window.alert(e);
    }
  };

  const logout = async () => {
    await apiLogout();
    await refreshUser();
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isCheckingAuthStatus,
        refreshUser,
        authenticate,
        isAuthenticating,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
