import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  name?: string;
}
const Greeting: React.FC<Props> = ({ name }) => {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  let greeting;

  if (currentHour >= 4 && currentHour < 12) {
    greeting = `Good morning`;
  } else if (currentHour >= 12 && currentHour < 18) {
    greeting = "Good afternoon";
  } else if (currentHour >= 18 && currentHour < 22) {
    greeting = "Good evening";
  } else {
    greeting = "Good night";
  }

  return (
    <Box>
      <Typography variant="h1" mb={4}>
        {greeting}
      </Typography>
    </Box>
  );
};

export default Greeting;
