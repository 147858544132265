import useApiMutationWithAuth, {
  IUseApiMutationResponse,
} from "src/api/hooks/useApiMutationWithAuth.hook";

const apiPath = "/poc/signedMessage";

interface SignedMessageBodyDTO {
  signingAddress: string;
  unsignedMessage: string;
  signedMessage: string;
  fullyExpandedSig: any;
}

export interface ISignedMessageResponseDTO {}

export interface IUseSignMessage
  extends IUseApiMutationResponse<
    SignedMessageBodyDTO,
    ISignedMessageResponseDTO
  > {
  signMessage: (
    body: SignedMessageBodyDTO
  ) => Promise<ISignedMessageResponseDTO>;
}

function useSignMessage(): IUseSignMessage {
  const useMutationResponse = useApiMutationWithAuth<
    SignedMessageBodyDTO,
    ISignedMessageResponseDTO
  >({
    method: "POST",
    apiPath,
  });

  return {
    ...useMutationResponse,
    signMessage: useMutationResponse.mutateAsync,
  };
}
export default useSignMessage;
