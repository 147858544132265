import { useContext } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthContext } from "src/context/auth.context";
import { Web3Provider } from "src/context/web3.context";
import HomePage from "./pages/home/Home.page";
import LandingPage from "./pages/landing/Landing.page";
import AccountSetUpPage from "./pages/onboarding/AccountSetUp.page";
import TestPage from "./pages/test/Test.page";
import ErrorPage from "./pages/error/Error.page";
import Navbar from "./components/navbar/Navbar";
import { SignUpStatus } from "./api/hooks/auth/useCurrentUser";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/test",
    element: <TestPage />,
  },
]);

function App() {
  const authContext = useContext(AuthContext);

  if (authContext.isCheckingAuthStatus) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Web3Provider>
      <Box height="100vh" display="flex" flexDirection="column">
        {!authContext.currentUser ? (
          <LandingPage />
        ) : authContext.currentUser?.signUpStatus ===
          SignUpStatus.SIGN_UP_SUCCESSFUL ? (
          <>
            <Navbar />
            <RouterProvider router={router} />
          </>
        ) : (
          <AccountSetUpPage />
        )}
      </Box>
    </Web3Provider>
  );
}

export default App;
